import React from "react";
import { graphql } from "gatsby";
import { Layout } from "@/components/Layout";
import { Meta } from "@/components/Meta";
import { Page } from "@/components/Page";
import { Sidebar } from "@/components/Sidebar";
import { useSiteMetadata } from "@/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBuilding, faGraduationCap, faRobot, faVials } from "@fortawesome/free-solid-svg-icons"
import { faPython, faRProject } from "@fortawesome/free-brands-svg-icons"

interface Props {
  data: {
    resumeJson: {
      experience: {
        company: string;
        type: string;
        duration: string;
        description: string;
        experiences: {
          exp: string;
        }[];
      }[];
      skills: {
        name: string;
        description: string;
      }[];
    };
  };
}

const PageTemplate: React.FC<Props> = ({ data }: Props) => {
  const { experience, skills } = data.resumeJson;

  if (!data.resumeJson) {
    return <div>Resume data not found.</div>;
  }

  return (
    <Layout>
      <Sidebar />
      <Page title="Hiroyuki Odake">
        <div className="timelines">
          <section className="timeline experience">
            <h2 className="timeline-title" id="experience">経歴</h2>
            <ul className="timeline-items">
              {experience.map((job, index) => (
                <li key={index} className="timeline-item">
                  {job.type === "education" ? (
                    <FontAwesomeIcon icon={faGraduationCap} className="fa-icon-edu" size="xs" />
                  ) : (
                    <FontAwesomeIcon icon={faBuilding} className="fa-icon-com" size="xs" />
                  )}
                  <h3>{job.company}</h3>
                  <p className="date-interval">
                    <time>{job.duration.split('-')[0]}</time> &ndash; <time>{job.duration.split('-')[1]}</time>
                  </p>
                  <p>{job.description}</p>
                  {job.experiences && (
                    <ul>
                      {job.experiences.map((exp, i) => (
                        <li key={i}>{exp.exp}</li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </section>
        </div>

        <section>
          <h2>Skills</h2>
          <ul className="skill-list">
            {skills.map((skill, index) => (
              <li key={index} className="skill-item">
                <div className="skill-header">
                  {skill.name === "Python" && <FontAwesomeIcon icon={faPython} className="skill-icon" size="lg" />}
                  {skill.name === "R" && <FontAwesomeIcon icon={faRProject} className="skill-icon" size="lg" />}
                  {skill.name === "AI" && <FontAwesomeIcon icon={faRobot} className="skill-icon" size="lg" />}
                  {skill.name === "Biology" && <FontAwesomeIcon icon={faVials} className="skill-icon" size="lg" />}
                  <h3 className="skill-name">{skill.name}</h3>
                </div>
                <p className="skill-description">{skill.description}</p>
              </li>
            ))}
          </ul>
        </section>
      </Page>
    </Layout >
  );
};

export const query = graphql`
  query {
    resumeJson {
      experience {
        company
        type
        duration
        description
        experiences {
          exp
        }
      }
      skills {
        name
        description
      }
    }
  }
`;

export const Head: React.FC<Props> = ({ data }) => {
  const { title } = useSiteMetadata();
  const description = "My CV";
  const pageTitle = "Career";

  return (
    <Meta
      title={`${pageTitle} - ${title}`}
      description={description}
    />
  );
};

export default PageTemplate;